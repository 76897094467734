:root {
    --risd-blue: #025FFAff;
    --pumpkin: #FF6F0Fff;
    --black: #000000ff;
    --turquoise: #33F3D8ff;
    --aquamarine: #62F1AAff;
}
body {
    color: aliceblue;
    background-color: var(--black);
}

.gq-highlight{
    color: var(--pumpkin);
}

.gq-info{
    color: var(--turquoise);
}

.gq-container {
    min-height: 100%;
    padding: 0px;
}

.gq-container-warp {
    background-position: center;
    background-size: cover;
}

.gq-img-fluid {
    width: 100%;
    height: auto;
    border-radius: 24px;
    border: solid 3px var(--pumpkin);
}

.gq-page-content {
    padding-top: 4%;
    width: 100%;
    min-height: 100vh;
    background-color: rgba(0, 0, 0, 0.586);
    color: aliceblue;
}

.gq-input label {
    display: inline-block;
    padding: 2%;
    min-width: 90px;
}

.gq-input input {
    display: block;
    min-width: 100%;
    padding: 5px;
    border: none;
    background: none;
    color: aliceblue;
    border-bottom: solid 0.6px rgba(255, 255, 255, 0.032);
}

.gq-input input:focus{
    border-bottom: solid 0.6px rgba(255, 255, 255, 0.979);
}

.gq-input input:focus {
    outline: none;
}

.gq-input {

    padding: 3%;
}


.gq-card-bg {
    background-color: rgba(0, 0, 0, 0.8);
    padding-top: 3%;
}

a {
    text-decoration: none;
}

button.gq-button {
    padding-left: 8%;
    ;
    padding-right: 8%;
    padding-top: 2px;
    padding-bottom: 3px;
    border: none;
    background-color: var(--pumpkin);
    color: aliceblue;
    box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.192);
}

h1{
    color: var(--pumpkin);
}

a{
    color: var(--aquamarine);
}

a.gq-a-button{
    padding-left: 8%  !important;
    ;
    padding-right: 8% !important;
    padding-top: 2px !important;
    padding-bottom: 3px !important;
    border: none !important;
    background-color: var(--pumpkin) !important;
    color: aliceblue !important;
    box-shadow: 1px 1px 2px rgba(255, 255, 255, 0.192) !important;
}
.transparent-cover {
    background-color: rgba(0, 0, 0, 0.7);
}

.transparent-cover-2 {
    background-color: rgba(0, 0, 0, 0.85);
}

.gq-button {
    padding-left: 4%;
    padding-right: 4%;
}

.gq-button:active{
    background-color: var(--black);
}

.gq-list-item{
    background-color: var(--black);
    /* color: white; */
    /* border: none; */
    margin-top: 2%;
    width:fit-content;
    display: inline-block !important;
    padding: 10px 20px; /* Adjust padding for size */
    background-color: #004fa4;
    margin-left: 5px;
    margin-right: 5px;
}

.progress-bar{
    background-color: var(--pumpkin);
    color: black;
    font-size: large;
    font-weight: bolder;
    padding: 2%;
}
.navbar{
    background-color: var(--black) !important;
}

.quizzez{
    border: solid 3px var(--pumpkin);
    border-radius: 25px;
}

.board{
    border: solid 3px var(--pumpkin);
    border-radius: 25px;
}
/* Share.css */
.share-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .share-container h1 {
    margin-bottom: 20px;
  }
  
  .share-icons {
    display: flex;
    gap: 15px; /* Adjust the gap between icons as needed */
  }
  
  .share-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 24px; /* Adjust size as needed */
    cursor: pointer; /* Makes icons clickable */
    text-decoration: none; /* Removes underline from links */
    color: inherit; /* Ensures icons inherit color from parent */
  }
  
  .share-icon i {
    transition: color 0.3s ease;
  }
  
  .share-icon:hover i {
    color: #007bff; /* Example hover color */
  }
  
  .icon-label {
    margin-top: 5px;
    font-size: 14px; /* Adjust label font size as needed */
  }
  
.q-wait{
    display: none;
}

.q-active{
    display: block !important;
}

.selected {
    background-color: #004fa4 !important;
}
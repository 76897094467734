/* Modal.css */
.gq-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .gq-modal-content {
    background: rgb(8, 1, 18);
    padding: 20px;
    border-radius: 5px;
    position: relative;
    width: 500px;
  }
  
  .gq-modal-close {
    position: absolute;
    color: white;
    top: 10px;
    right: 10px;
    border: none;
    background: transparent;
    font-size: 24px;
    cursor: pointer;
  }
  